<template>
  <div class="card mb-3">
    <div class="card-body">
      <h5 class="float-left" style="padding-top: 2pt">{{ title }}</h5>

      <a
        :href="src"
        class="btn btn-sm btn-outline-primary float-right"
        download=""
      >
        <i class="fas fa-file-download"></i> Download
      </a>

      <br />
    </div>

    <div class="text-center mb-3" v-if="state === 'loading'">
      <div
        class="spinner-border mt-3"
        role="status"
        style="width: 3rem; height: 3rem"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <br />
      <br />
    </div>

    <Transition name="fade">
      <div class="pdf-document" v-show="state === 'ready'">
        <div class="pdf-page" v-for="i in numPages" :key="i">
          <hr />
          <pdf
            class="pdf-page-content"
            :src="pdfDocument"
            :id="i"
            :page="i"
            :annotation="true"
            :resize="true"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import pdfvuer from "pdfvuer";

export default {
  name: "PdfView",
  props: ["src", "title"],
  data: function () {
    return { pdfDocument: undefined, state: "loading", numPages: 0 };
  },
  components: {
    pdf: pdfvuer,
  },
  methods: {
    display() {
      var self = this;
      self.state = "loading";
      console.log("Loading...");
      self.pdfDocument = pdfvuer.createLoadingTask(self.src);
      self.pdfDocument.then((pdf) => {
        self.numPages = pdf.numPages;
        self.state = "ready";
        console.log("Ready");
      });
    },
  },
  mounted() {
    console.log("mounted");
    this.display();
  },
};
</script>

<style scoped>
.pdf-page-content {
  margin: 5px;
}
.pdf-page hr {
  padding: 0;
  margin: 0;
}
</style>

<style>
.pdf-document a {
  border-radius: 10px !import;
  background-color: rgba(0, 123, 255, 0.1) !important;
  box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.25) !important;
  transition: 0.3s;
}
.pdf-document a:hover {
  background-color: rgba(255, 193, 6, 0.5) !important;
  box-shadow: 0px 0px 15px rgba(255, 193, 6, 1) !important;
  transition: 0.3s;
}
</style>
